import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Heart, Zap, CheckCircle } from 'lucide-react';
import LoadingSpinner from './LoadingSpinner'; // Adjust the import path as necessary

interface Protocol {
  id: string;
  content: string;
}

interface Box {
  id: string;
  title: string;
  items: Protocol[];
}

interface BoxesState {
  [key: string]: Box;
}

interface UserStats {
  xp: number;
  lives: number;
  streak: number;
  timeUntilNextLife: number | null;
}

// This component wraps react-beautiful-dnd components and disables strict mode
const StrictModeDroppable = ({ children, ...props }: React.ComponentProps<typeof Droppable>) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const MatchingGame: React.FC = () => {
  const [protocols, setProtocols] = useState<Protocol[]>([]);
  const [boxes, setBoxes] = useState<BoxesState>({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userStats, setUserStats] = useState<UserStats>({ xp: 0, lives: 3, streak: 0, timeUntilNextLife: null });
  const [timeRemaining, setTimeRemaining] = useState<number>(120);
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [characterMood, setCharacterMood] = useState<'neutral' | 'thinking' | 'correct' | 'incorrect'>('neutral');
  const [showCelebration, setShowCelebration] = useState(false);
  const [celebrationMessage, setCelebrationMessage] = useState('');

  const playAnswerSound = useCallback((isCorrect: boolean) => {
    const audioContext = new (window.AudioContext || (window as any).WebkitAudioContext)();
    const masterGain = audioContext.createGain();
    masterGain.connect(audioContext.destination);
    masterGain.gain.setValueAtTime(0.7, audioContext.currentTime);
    const playNote = (frequency: number, startTime: number, duration: number) => {
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();
      oscillator.connect(gainNode);
      gainNode.connect(masterGain);
      oscillator.type = 'sine';
      oscillator.frequency.setValueAtTime(frequency, startTime);
      gainNode.gain.setValueAtTime(0, startTime);
      gainNode.gain.linearRampToValueAtTime(1, startTime + 0.01);
      gainNode.gain.linearRampToValueAtTime(0, startTime + duration - 0.01);
      oscillator.start(startTime);
      oscillator.stop(startTime + duration);
    };
    const now = audioContext.currentTime;
    if (isCorrect) {
      playNote(523.25, now, 0.15);
      playNote(659.25, now + 0.15, 0.15);
      playNote(783.99, now + 0.3, 0.3);
    } else {
      playNote(392.00, now, 0.15);
      playNote(349.23, now + 0.15, 0.15);
      playNote(329.63, now + 0.3, 0.3);
    }
    setTimeout(() => masterGain.disconnect(), 1000);
  }, []);

  useEffect(() => {
    const fetchQuestion = async () => {
      setIsLoading(true);
      try {
        // Simulated API call
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        // Stubbed response
        const response = {
          protocols: [
            { id: 'proto1', content: 'HTTPS' },
            { id: 'proto2', content: 'HTTP' },
            { id: 'proto3', content: 'SFTP' },
            { id: 'proto4', content: 'FTP' },
            { id: 'proto5', content: 'SSH' },
            { id: 'proto6', content: 'Telnet' },
            { id: 'proto7', content: 'TLS' },
            { id: 'proto8', content: 'SMTP' },
          ],
          boxes: {
            box1: { id: 'box1', title: 'Encrypted', items: [] },
            box2: { id: 'box2', title: 'Unencrypted', items: [] },
          }
        };

        setProtocols(response.protocols);
        setBoxes(response.boxes);
        setTimeRemaining(120);
        setTimerActive(true);
      } catch (error) {
        console.error('Error fetching question:', error);
        setError('Failed to fetch the question. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestion();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timerActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      handleTimerExpired();
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timerActive, timeRemaining]);

  const handleTimerExpired = () => {
    setTimerActive(false);
    setIsAnswerChecked(true);
    setCharacterMood('incorrect');
    playAnswerSound(false);
    setTimeout(() => {
      setIsAnswerChecked(false);
      setCharacterMood('neutral');
      // In a real scenario, you'd fetch the next question here
    }, 2000);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === 'protocols' && destination.droppableId !== 'protocols') {
      const newProtocols = Array.from(protocols);
      const [reorderedItem] = newProtocols.splice(source.index, 1);
      
      setProtocols(newProtocols);
      
      setBoxes(prev => ({
        ...prev,
        [destination.droppableId]: {
          ...prev[destination.droppableId],
          items: [...prev[destination.droppableId].items, reorderedItem]
        }
      }));
    }
  };

  const handleSubmit = () => {
    setIsAnswerChecked(true);
    setCharacterMood('thinking');
    
    // Simulated answer checking
    setTimeout(() => {
      const encryptedProtocols = ['HTTPS', 'SFTP', 'SSH', 'TLS'];
      const unencryptedProtocols = ['HTTP', 'FTP', 'Telnet', 'SMTP'];
      
      const isCorrect = boxes.box1.items.every(item => encryptedProtocols.includes(item.content)) &&
                        boxes.box2.items.every(item => unencryptedProtocols.includes(item.content)) &&
                        (boxes.box1.items.length + boxes.box2.items.length === protocols.length);

      setCharacterMood(isCorrect ? 'correct' : 'incorrect');
      playAnswerSound(isCorrect);

      if (isCorrect) {
        setShowCelebration(true);
        setCelebrationMessage('Correct! You\'ve successfully categorized the protocols!');
        setUserStats(prev => ({
          ...prev,
          xp: prev.xp + 10,
          streak: prev.streak + 1
        }));
      } else {
        setUserStats(prev => ({
          ...prev,
          lives: Math.max(0, prev.lives - 1),
          streak: 0
        }));
      }

      setTimeout(() => {
        setShowCelebration(false);
        setIsAnswerChecked(false);
        setCharacterMood('neutral');
        // In a real scenario, you'd fetch the next question here
      }, 2000);
    }, 1000);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="max-w-2xl mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
            <div className="flex items-center">
              <Zap className="text-yellow-500 mr-1" />
              <span>XP: {userStats.xp}</span>
            </div>
            <div className="flex items-center">
              <Heart className="text-red-500 mr-1" />
              <span>Lives: {userStats.lives}</span>
            </div>
            <div className="flex items-center">
              <CheckCircle className="text-green-500 mr-1" />
              <span>Streak: {userStats.streak}</span>
            </div>
          </div>
          <div className="text-xl font-bold text-blue-600">
            {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, '0')}
          </div>
        </div>
        <div className="flex justify-center mb-6">
          <div className={`w-24 h-24 rounded-full flex items-center justify-center transition-all duration-300 ${
            characterMood === 'thinking' ? 'bg-yellow-500' :
            characterMood === 'correct' ? 'bg-green-500' :
            characterMood === 'incorrect' ? 'bg-red-500' :
            'bg-blue-500'
          }`}>
            <span className="text-4xl">
              {characterMood === 'thinking' ? '🤔' :
               characterMood === 'correct' ? '😃' :
               characterMood === 'incorrect' ? '😢' :
               '🦊'}
            </span>
          </div>
        </div>
        <h2 className="text-2xl font-bold mb-6 text-gray-800">
          Categorize the protocols as Encrypted or Unencrypted
        </h2>
        <div className="flex gap-4">
          <div className="w-1/3">
            <h3 className="text-lg font-semibold mb-2">Protocols</h3>
            <StrictModeDroppable droppableId="protocols">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef} className="bg-gray-100 p-2 rounded-lg min-h-[200px]">
                  {protocols.map((protocol, index) => (
                    <Draggable key={protocol.id} draggableId={protocol.id} index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-white p-2 mb-2 rounded shadow"
                        >
                          {protocol.content}
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </StrictModeDroppable>
          </div>
          <div className="w-2/3 flex gap-4">
            {Object.values(boxes).map((box) => (
              <div key={box.id} className="flex-1">
                <h3 className="text-lg font-semibold mb-2">{box.title}</h3>
                <StrictModeDroppable droppableId={box.id}>
                  {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} className="bg-gray-100 p-2 rounded-lg min-h-[200px]">
                      {box.items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="bg-white p-2 mb-2 rounded shadow"
                            >
                              {item.content}
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  )}
                </StrictModeDroppable>
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="mt-4 w-full p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
          disabled={isAnswerChecked}
        >
          Submit
        </button>
        {showCelebration && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-green-500 text-white p-6 rounded-lg shadow-lg max-w-md">
              <h2 className="text-3xl font-bold mb-4">{celebrationMessage}</h2>
            </div>
          </div>
        )}
      </div>
    </DragDropContext>
  );
};

export default MatchingGame;
