// src/components/ComparisonSlide.tsx
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ComparisonSlide as ComparisonSlideType } from '../types';

interface ComparisonSlideProps {
  slide: ComparisonSlideType;
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 1rem;
  background-color: #f0f0f0;
  animation: ${fadeIn} 0.5s ease-in;
`;

const Section = styled.div`
  width: 48%;
  padding: 1.5rem;
  border: 2px solid #333;
  border-radius: 10px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Title = styled.h3`
  font-size: clamp(1.6rem, 2.5vw, 2.2rem);
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: clamp(1rem, 2vw, 1.6rem);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateX(5px);
  }
`;

const BulletPoint = styled.span`
  margin-right: 0.5rem;
  color: #4CAF50;
  font-size: 1.2em;
  font-weight: bold;
`;

const Separator = styled.div`
  width: 4%;
`;

const ComparisonSlide: React.FC<ComparisonSlideProps> = ({ slide }) => {
  return (
    <SlideContainer>
      <Section>
        <Title>{slide.leftTitle}</Title>
        <List>
          {slide.leftPoints.map((point, index) => (
            <ListItem key={index}>
              <BulletPoint>•</BulletPoint>
              {point}
            </ListItem>
          ))}
        </List>
      </Section>
      <Separator />
      <Section>
        <Title>{slide.rightTitle}</Title>
        <List>
          {slide.rightPoints.map((point, index) => (
            <ListItem key={index}>
              <BulletPoint>•</BulletPoint>
              {point}
            </ListItem>
          ))}
        </List>
      </Section>
    </SlideContainer>
  );
};

export default ComparisonSlide;
